<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Given that the
        <stemble-latex content="$\text{K}_\text{a}$" />
        of hydrocyanic acid,
        <chemical-latex content="HCN(aq)," />
        is
        <stemble-latex content="$6.2\times 10^{-10}$" />
        at
        <stemble-latex content="$25^\circ\text{C,}$" />
        determine the
        <stemble-latex content="$\text{K}_\text{b}$" />
        value for
        <chemical-latex content="CN-" />
        at that temperature.
      </p>

      <calculation-input
        v-model="inputs.Kb"
        prepend-text="$\text{K}_\text{b}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question140',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Kb: null,
      },
    };
  },
};
</script>
